import {
  AUTOCOMPLETE_QUEUE,
  AUTOCOMPLETE_FINISHED,
  AUTOCOMPLETE_ERROR,
  AUTOCOMPLETE_RESET,
} from 'actions/AutocompleteActions';

import { ClientOption, ClientValue, clientOptionFormatter } from 'components/forms/autocomplete/Client';
import { VendorOption, VendorValue, vendorOptionFormatter } from 'components/forms/autocomplete/Vendor';
import {
  EmailTemplateOption,
  EmailTemplateValue,
  emailTemplateOptionFormatter,
} from 'components/forms/autocomplete/EmailTemplate';
import { PipelineOption, PipelineValue, pipelineOptionFormatter } from 'components/forms/autocomplete/Pipeline';
import { CountryOption, CountryValue, countryOptionFormatter } from 'components/forms/autocomplete/Country';
import { CurrencyOption, CurrencyValue, currencyOptionFormatter } from 'components/forms/autocomplete/Currency';
import {
  QuestionnaireOption,
  QuestionnaireValue,
  questionnaireOptionFormatter,
} from 'components/forms/autocomplete/Questionnaire';
import { DocTypeOption, DocTypeValue, docTypeOptionFormatter } from 'components/forms/autocomplete/DocType';
import {
  DocTemplateOption,
  DocTemplateValue,
  docTemplateOptionFormatter,
} from 'components/forms/autocomplete/DocTemplate';
import { TeamOption, TeamValue, teamOptionFormatter } from 'components/forms/autocomplete/Team';
import { TeamTypeOption, TeamTypeValue, teamTypeOptionFormatter } from 'components/forms/autocomplete/TeamType';
import { CategoryOption, CategoryValue, categoryOptionFormatter } from 'components/forms/autocomplete/Category';
import { BrandOption, BrandValue, brandOptionFormatter } from 'components/forms/autocomplete/Brand';
import {
  ProductTemplateOption,
  ProductTemplateValue,
  productTemplateOptionFormatter,
} from 'components/forms/autocomplete/ProductTemplate';
import { ModelOption, ModelValue, modelOptionFormatter } from 'components/forms/autocomplete/Model';
import { ModelGroupOption, ModelGroupValue, modelGroupOptionFormatter } from 'components/forms/autocomplete/ModelGroup';
import { ColorOption, ColorValue, colorOptionFormatter } from 'components/forms/autocomplete/Color';
import { UomOption, UomValue } from 'components/forms/autocomplete/Uom';
import {
  CompanyLocationOption,
  CompanyLocationValue,
  companyLocationOptionFormatter,
} from 'components/forms/autocomplete/CompanyLocation';
import {
  WhiteLabelLocationOption,
  WhiteLabelLocationValue,
  whiteLabelLocationOptionFormatter,
} from 'components/forms/autocomplete/WhiteLabelLocation';
import { AttributeOption, AttributeValue, attributeOptionFormatter } from 'components/forms/autocomplete/Attribute';
import {
  AttributeGroupOption,
  AttributeGroupValue,
  attributeGroupOptionFormatter,
} from 'components/forms/autocomplete/AttributeGroup';
import { OrderOption, OrderValue, orderOptionFormatter } from 'components/forms/autocomplete/Order';
import { ItemOption, ItemValue, itemOptionFormatter } from 'components/forms/autocomplete/Item';
import { PageOption, PageValue, pageOptionFormatter } from 'components/forms/autocomplete/Page';
import {
  DynamicElementOption,
  DynamicElementValue,
  dynamicElementOptionFormatter,
} from 'components/forms/autocomplete/DynamicElement';
import {
  MyWhiteLabelProjectOption,
  MyWhiteLabelProjectValue,
  myWhiteLabelProjectOptionFormatter,
} from 'components/forms/autocomplete/MyWhiteLabelProject';
import {
  DynamicDataMetaIdentifierOption,
  DynamicDataMetaIdentifierValue,
  dynamicDataMetaIdentifierOptionFormatter,
} from 'components/forms/autocomplete/DynamicDataMetaIdentifier';
import { RoleOption, RoleValue, roleOptionFormatter } from 'components/forms/autocomplete/Role';
import {
  WhiteLabelSettingsOption,
  WhiteLabelSettingsValue,
  whiteLabelSettingsOptionFormatter,
} from 'components/forms/autocomplete/WhiteLabelSettings';
import {
  MainMenuVersionOption,
  MainMenuVersionValue,
  mainMenuVersionOptionFormatter,
} from 'components/forms/autocomplete/MainMenuVersion';
import {
  WhiteLabelPluginOption,
  WhiteLabelPluginValue,
  whiteLabelPluginOptionFormatter,
} from 'components/forms/autocomplete/WhiteLabelPlugin';

const defaultAutocompleteState = {
  pending: false,
  hasError: false,
  errors: [],
  response: null,
};

const initialState = {
  client: {
    ...defaultAutocompleteState,
    url: '/api/clients.json',
    optionComponent: ClientOption,
    valueComponent: ClientValue,
    formatter: clientOptionFormatter,
  },
  clientProvision: {
    ...defaultAutocompleteState,
    url: '/api/clients.json?filters&filters[roles]=ROLE_PROVISION_VALID&',
    optionComponent: ClientOption,
    valueComponent: ClientValue,
    formatter: clientOptionFormatter,
  },
  vendor: {
    ...defaultAutocompleteState,
    url: '/api/clients.json?filters[type]=company&filters[isDeleted]=false&filters[roles]=ROLE_VENDOR&',
    optionComponent: VendorOption,
    valueComponent: VendorValue,
    formatter: vendorOptionFormatter,
  },
  user: {
    ...defaultAutocompleteState,
    url: '/api/clients.json?filters[type]=employee&',
    optionComponent: ClientOption,
    valueComponent: ClientValue,
    formatter: clientOptionFormatter,
  },
  customer: {
    ...defaultAutocompleteState,
    url: '/api/clients/for/autocomplete.json?filters[type]=company&', /** Although it is the type "customer", we are looking for the type company because the controller uses the type to assemble the role and searches for it. */
    optionComponent: ClientOption,
    valueComponent: ClientValue,
    formatter: clientOptionFormatter,
  },
  sharing: {
    ...defaultAutocompleteState,
    url: '/api/clients/for/autocomplete.json?depth=shallow&filters[type]=!company&filters[isActive]=true&',
    optionComponent: ClientOption,
    valueComponent: ClientValue,
    formatter: clientOptionFormatter,
  },
  holdingCompany: {
    ...defaultAutocompleteState,
    url: '/api/clients/holding.json',
    optionComponent: ClientOption,
    valueComponent: ClientValue,
    formatter: clientOptionFormatter,
  },
  invoice: { ...defaultAutocompleteState, url: '' },
  emailTemplate: {
    ...defaultAutocompleteState,
    url: '/api/templates.json',
    optionComponent: EmailTemplateOption,
    valueComponent: EmailTemplateValue,
    formatter: emailTemplateOptionFormatter,
  },
  pipeline: {
    ...defaultAutocompleteState,
    url: '/api/pipelines.json',
    optionComponent: PipelineOption,
    valueComponent: PipelineValue,
    formatter: pipelineOptionFormatter,
  },
  country: {
    ...defaultAutocompleteState,
    url: '/api/countries/list.json',
    optionComponent: CountryOption,
    valueComponent: CountryValue,
    formatter: countryOptionFormatter,
  },
  currency: {
    ...defaultAutocompleteState,
    url: '/api/currencies/list.json',
    optionComponent: CurrencyOption,
    valueComponent: CurrencyValue,
    formatter: currencyOptionFormatter,
  },
  questionnaire: {
    ...defaultAutocompleteState,
    url: '/api/questionnaires.json',
    optionComponent: QuestionnaireOption,
    valueComponent: QuestionnaireValue,
    formatter: questionnaireOptionFormatter,
  },
  docType: {
    ...defaultAutocompleteState,
    url: '/api/docs/types.json',
    optionComponent: DocTypeOption,
    valueComponent: DocTypeValue,
    formatter: docTypeOptionFormatter,
  },
  docTemplate: {
    ...defaultAutocompleteState,
    url: '/api/docs/templates.json',
    optionComponent: DocTemplateOption,
    valueComponent: DocTemplateValue,
    formatter: docTemplateOptionFormatter,
  },
  teamType: {
    ...defaultAutocompleteState,
    url: '/api/team/types.json',
    optionComponent: TeamTypeOption,
    valueComponent: TeamTypeValue,
    formatter: teamTypeOptionFormatter,
  },
  category: {
    ...defaultAutocompleteState,
    url: '/api/categories.json',
    optionComponent: CategoryOption,
    valueComponent: CategoryValue,
    formatter: categoryOptionFormatter,
  },
  brand: {
    ...defaultAutocompleteState,
    url: '/api/brands.json',
    optionComponent: BrandOption,
    valueComponent: BrandValue,
    formatter: brandOptionFormatter,
  },
  productTemplate: {
    ...defaultAutocompleteState,
    url: '/api/producttemplates.json',
    optionComponent: ProductTemplateOption,
    valueComponent: ProductTemplateValue,
    formatter: productTemplateOptionFormatter,
  },
  model: {
    ...defaultAutocompleteState,
    url: '/api/models.json',
    optionComponent: ModelOption,
    valueComponent: ModelValue,
    formatter: modelOptionFormatter,
  },
  modelGroup: {
    ...defaultAutocompleteState,
    url: '/api/modelgroups.json',
    optionComponent: ModelGroupOption,
    valueComponent: ModelGroupValue,
    formatter: modelGroupOptionFormatter,
  },
  color: {
    ...defaultAutocompleteState,
    url: '',
    optionComponent: ColorOption,
    valueComponent: ColorValue,
    formatter: colorOptionFormatter,
  },
  uom: {
    ...defaultAutocompleteState,
    url: '/api/items/library/uom.json',
    optionComponent: UomOption,
    valueComponent: UomValue,
  },
  team: {
    ...defaultAutocompleteState,
    url: '/api/teams.json',
    optionComponent: TeamOption,
    valueComponent: TeamValue,
    formatter: teamOptionFormatter,
  },
  companyLocation: {
    ...defaultAutocompleteState,
    url: '/api/locations.json',
    optionComponent: CompanyLocationOption,
    valueComponent: CompanyLocationValue,
    formatter: companyLocationOptionFormatter,
  },
  whiteLabelLocation: {
    ...defaultAutocompleteState,
    url: '/api/whitelabellocations.json',
    optionComponent: WhiteLabelLocationOption,
    valueComponent: WhiteLabelLocationValue,
    formatter: whiteLabelLocationOptionFormatter,
  },
  attribute: {
    ...defaultAutocompleteState,
    url: '/api/attributes.json',
    optionComponent: AttributeOption,
    valueComponent: AttributeValue,
    formatter: attributeOptionFormatter,
  },
  attributeGroup: {
    ...defaultAutocompleteState,
    url: '/api/attributeGroups.json',
    optionComponent: AttributeGroupOption,
    valueComponent: AttributeGroupValue,
    formatter: attributeGroupOptionFormatter,
  },
  order: {
    ...defaultAutocompleteState,
    url: '/api/orders.json',
    optionComponent: OrderOption,
    valueComponent: OrderValue,
    formatter: orderOptionFormatter,
  },
  item: {
    ...defaultAutocompleteState,
    url: '/api/items.json',
    optionComponent: ItemOption,
    valueComponent: ItemValue,
    formatter: itemOptionFormatter,
  },
  car: {
    ...defaultAutocompleteState,
    url: '/api/items.json?filters[type]=car&',
    optionComponent: ItemOption,
    valueComponent: ItemValue,
    formatter: itemOptionFormatter,
  },
  page: {
    ...defaultAutocompleteState,
    url: '/api/pages.json',
    optionComponent: PageOption,
    valueComponent: PageValue,
    formatter: pageOptionFormatter,
  },
  dynamicElement: {
    ...defaultAutocompleteState,
    url: '/api/dynamicelements.json?filters[isReference]=true',
    optionComponent: DynamicElementOption,
    valueComponent: DynamicElementValue,
    formatter: dynamicElementOptionFormatter,
  },
  myWhiteLabelProject: {
    ...defaultAutocompleteState,
    url: '/api/whitelabels/my.json',
    optionComponent: MyWhiteLabelProjectOption,
    valueComponent: MyWhiteLabelProjectValue,
    formatter: myWhiteLabelProjectOptionFormatter,
  },
  dynamicDataMetaIdentifier: {
    ...defaultAutocompleteState,
    url: '/api/metaidentifiers.json',
    optionComponent: DynamicDataMetaIdentifierOption,
    valueComponent: DynamicDataMetaIdentifierValue,
    formatter: dynamicDataMetaIdentifierOptionFormatter,
  },
  role: {
    ...defaultAutocompleteState,
    url: '/api/roles.json',
    optionComponent: RoleOption,
    valueComponent: RoleValue,
    formatter: roleOptionFormatter,
  },
  roleMulti: { ...defaultAutocompleteState, url: '/api/roles.json', formatter: roleOptionFormatter },
  whiteLabelSettings: {
    ...defaultAutocompleteState,
    url: '/api/whitelabels.json',
    optionComponent: WhiteLabelSettingsOption,
    valueComponent: WhiteLabelSettingsValue,
    formatter: whiteLabelSettingsOptionFormatter,
  },
  mainMenuVersion: {
    ...defaultAutocompleteState,
    url: '/api/mainmenuversions.json',
    optionComponent: MainMenuVersionOption,
    valueComponent: MainMenuVersionValue,
    formatter: mainMenuVersionOptionFormatter,
  },
  whiteLabelPlugin: {
    ...defaultAutocompleteState,
    url: '/api/whitelabelplugins.json',
    optionComponent: WhiteLabelPluginOption,
    valueComponent: WhiteLabelPluginValue,
    formatter: whiteLabelPluginOptionFormatter,
  },
};

export default function autocomplete(state = initialState, action) {
  switch (action.type) {
    case AUTOCOMPLETE_QUEUE: {
      const newState = { ...state };
      const newAutocompleteState = initialState[action.payload];

      newState[action.payload] = {
        ...newAutocompleteState,
        pending: true,
        hasError: false,
        errors: [],
        response: null,
      };
      return newState;
    }

    case AUTOCOMPLETE_FINISHED: {
      const newState = { ...state };
      const newAutocompleteState = initialState[action.payload.autocomplete];

      newState[action.payload.autocomplete] = {
        ...newAutocompleteState,
        pending: false,
        hasError: false,
        errors: [],
        response:
          newAutocompleteState.formatter === undefined
            ? action.payload.response.data
            : newAutocompleteState.formatter(action.payload.response.data),
      };
      return newState;
    }

    case AUTOCOMPLETE_ERROR: {
      const newState = { ...state };
      const newAutocompleteState = initialState[action.payload.autocomplete];

      newState[action.payload.autocomplete] = {
        ...newAutocompleteState,
        pending: false,
        hasError: true,
        errors: action.payload.errors,
        response: null,
      };
      return newState;
    }

    case AUTOCOMPLETE_RESET: {
      const newState = { ...state };
      const newAutocompleteState = initialState[action.payload];

      newState[action.payload] = {
        ...newAutocompleteState,
        pending: false,
        hasError: false,
        errors: [],
        response: null,
      };
      return newState;
    }
  }

  return state;
}
