import React from 'react';
import { Col, Button, ButtonGroup, DropdownButton } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import _, { forEach as _forEach, isObject as _isObject } from 'lodash';
import FormImageWithPreview from 'components/forms/FormImageWithPreview';
import { formStatusDispatchHelper } from 'actions/FormStatusActions';
import { addNotification } from 'actions/NotificationsActions';
import { OPTION_ENABLED, OPTION_DISABLED } from 'helpers/clients';
import { createObjectURL } from 'helpers/upload';
import * as XLSX from 'xlsx';
import FormFile from 'components/forms/FormFile';
import FormCodeEditor from 'components/forms/FormCodeEditor';
import { cartAddEdition } from '../../actions/CartActions';
import may from '../../security';
import FormAutocomplete from '../../components/forms/FormAutocomplete';
import Loader from '../../components/layout/Loader';
import FormInput from '../../components/forms/FormInput';
import { getPDFR, setVariables, uploadExcelFileR, getTimelineR } from '../../actions/EditorActions';
import TimelineOverlay from './timelineComponents/TimelineOverlay';
import TimelineDialogModal from './timelineComponents/TimelineDialogModal';

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

class EditorToolbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSaveTo: false,
      savingTo: false,
      previewing: false,
      printing: false,
      exporting: false,
      currentSettings: props.editionSettings,
      showTimelineOverlay: false,
      openImageOverlay: false,
      openedImage: null,
      pending: false,
      saveBeforeToCart: false,
      selectedFile: null,
      showSerialLetterOptions: false,
      fileData: undefined,
      uploadingPdf: false,
      pdfUrl: '',
    };
  }

  componentDidMount = () => {
    const { currentSettings } = this.state;
    const { editionTitle } = this.props;

    this.changeSettings('websiteTitle', editionTitle);
  };

  componentDidUpdate = prevProps => {
    const {
      edition,
      addToCart,
      history,
      appIntl,
      intl: { messages },
      cartPending,
    } = this.props;
    const { saveBeforeToCart } = this.state;
    if (prevProps.savingR !== this.props.savingR && this.props.savingR === false) {
      if (saveBeforeToCart === true) {
        this.setState({ saveBeforeToCart: false }, () => {
          addToCart(edition);
        });
      }
    }
    if (prevProps.cartPending !== cartPending && cartPending === false) {
      let cart = 'cart';
      if (appIntl.urlPrefix === '/de/') {
        cart = 'warenkorb';
      }

      const options = {
        title: messages.editor.product_in_cart,
        message: edition.title + messages.editor.added_to_cart,
        buttons: [
          {
            label: messages.editor.great,
            onClick: () => {},
          },
          {
            label: messages.editor.to_cart,
            onClick: () => {
              history.push(appIntl.urlPrefix + cart);
            },
          },
        ],
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
      };

      confirmAlert(options);
    }

    const { doNotify, formStatusLandingPageFavIcon } = this.props;
    const fileUploadedMessage = messages.file_uploaded;

    if (
      prevProps.formStatusLandingPageFavIcon.pending &&
      !formStatusLandingPageFavIcon.pending &&
      !formStatusLandingPageFavIcon.hasError
    ) {
      // image has been uploaded, update the settings
      this.changeSettings('lpFavIcon', formStatusLandingPageFavIcon.response);
      doNotify(1, fileUploadedMessage);
      this.forceUpdate();
    }
  };

  fileUpload = (name, images) => {
    const {
      intl: { messages },
      uploadFavIcon,
      doNotify,
    } = this.props;
    if (_isObject(images) && images.length === 1) {
      const img = new Image();
      img.src = createObjectURL(images[0]);
      img.onload = () => {
        // check min and max height
        const imgWidth = img.width;
        const imgHeight = img.height;

        if (imgWidth <= 64 && imgWidth === imgHeight) {
          // set formdata
          const fd = new FormData();
          fd.append('file', images[0]);
          fd.append('logoType', name);

          // upload image
          switch (name) {
            case 'lpFavIcon':
              uploadFavIcon(fd);
              break;
          }
        } else {
          doNotify(4, messages.upload_favicon_error);
        }
      };
    }
  };

  fileReset = () => {
    this.changeSettings('lpFavIcon', null);
    this.forceUpdate();
  };

  goBackToCampaign = campaignId => {
    const { history, appIntl } = this.props;
    history.push(appIntl.urlPrefix + 'account/campaigns/' + campaignId);
  };

  pageDown = () => {
    const { currentPage, updateCurrentPage } = this.props;
    updateCurrentPage(currentPage - 1);
  };

  pageUp = () => {
    const { currentPage, updateCurrentPage } = this.props;
    updateCurrentPage(currentPage + 1);
  };

  gotoPage = key => {
    const { updateCurrentPage } = this.props;
    updateCurrentPage(parseInt(key, 10) - 1);
  };

  save = () => {
    const { checkDataPolicy, checkImpressum, website, saveContent, getDataPolicyWarning } = this.props;
    if ((checkDataPolicy() === false || checkImpressum() === false) && website === true) {
      getDataPolicyWarning('manual');
    } else {
      saveContent();
      this.setState({ callTimelineApi: true });
    }
  };

  initAction = () => {
    const { initTemplate } = this.props;
    initTemplate();
  };

  showSaveTo = (show = true) => {
    this.setState({
      showSaveTo: show,
    });
    if (show) {
      this.props.getCampaigns();
    }
  };

  closeSaveTo = () => {
    this.setState({ showSaveTo: false });
  };

  saveTo = () => {
    this.setState({
      savingTo: true,
    });

    this.props.saveTo(this.campaignSelect.state.currentValue, this.title.state.value);
  };

  showTimelineOverlay = () => {
    const { getTimelineR, editionId, updateTimelineR } = this.props;
    const start = 0;
    const limit = 10;
    if (updateTimelineR) {
      getTimelineR(editionId, start, limit);
    }
    this.setState({ showTimelineOverlay: true });
  };

  openImageOverlay = image => this.setState({ openImageOverlay: true, openedImage: image });

  closeTimelineOverlay = () => this.setState({ showTimelineOverlay: false });

  closeImageOverlay = () => this.setState({ openImageOverlay: false, openedImage: null });

  saveSettings = () => {
    const { currentSettings } = this.state;
    const { closeSettingsF } = this.props;
    this.props.changeEditionTitle(this.title.state.value);
    this.props.changeEditionSettings(currentSettings);
    closeSettingsF();
  };

  changeSettings = (name, value) => {
    const { currentSettings } = this.state;
    const valueIndex = _.findIndex(currentSettings, ['name', name]);
    if (valueIndex < 0) {
      currentSettings.push({ name, value });
    } else {
      currentSettings[valueIndex] = { name, value };
    }
    this.setState({
      currentSettings,
    });

    const check = _.find(currentSettings, ['name', 'serialletter'])
      ? _.find(currentSettings, ['name', 'serialletter']).value || ''
      : false;
    this.setState({ showSerialLetterOptions: check });
  };

  download = (url, filename) => {
    fetch(url).then(function(t) {
      return t.blob().then(b => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', filename);
        a.click();
      });
    });
  };

  // On file select (from the pop up)
  onFileChange = event => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = event => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      this.setState({ fileData: sheetData });
    };

    reader.readAsArrayBuffer(file);
  };

  onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append('myFile', this.state.selectedFile, this.state.selectedFile.name);

    const fileDataJSON = JSON.stringify(this.state.fileData);
    formData.append('fileData', fileDataJSON);

    // Send formData object
    const { editionId } = this.props;
    this.props.uploadExcelFileR(formData, editionId);
  };

  getPDF = type => {
    const { editionId, getPDFR, setVariables } = this.props;
    const {
      intl: { messages },
    } = this.props;
    let api = 'previewcopies';
    if (type === 'print') {
      this.setState({ printing: true });
      setVariables('printing');
      api = 'mastercopies';
    } else if (type === 'image') {
      this.setState({ exporting: true });
      setVariables('exporting');
      api = 'images/mastercopies';
    } else {
      this.setState({ previewing: true });
      setVariables('previewing');
    }
    getPDFR(api, editionId, messages);
  };

  addToCart = () => {
    this.setState({ saveBeforeToCart: true }, () => {
      this.save();
    });
    // you can find addToCart call from Action into the componentDidUpdate function
  };

  undo = id => {
    this.setState({ pending: true });
    const {
      intl: { messages },
      location,
    } = this.props;
    const options = {
      title: messages.editor.are_you_sure,
      message: messages.editor.reset_to_selected_status,
      buttons: [
        {
          label: messages.editor.abort,
          onClick: () => this.closeTimelineOverlay(),
        },
        {
          label: messages.editor.reset_to_version,
          onClick: () =>
            Axios.put(`/api/editions/${this.props.editionId}/reset.json`, { resetTo: id })
              .then(response => {
                if (response.status === 200) {
                  window.location.reload();
                }
              })
              .catch(error => {
                if (Axios.isCancel(error)) {
                  console.log('put Request canceled');
                } else {
                  console.log(error);
                }
              }),
        },
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
    };
    confirmAlert(options);
  };

  uploadPdfFiles = (name, value) => {
    const {
      intl: { messages },
    } = this.props;
    this.setState({ uploadingPdf: true });
    const formData = new FormData();
    _forEach(value, (file, i) => {
      formData.append('file' + i, file);
    });

    // formData.append("json", JSON.stringify(mediaData));

    this.uploadPDF(formData)
      .then(response => {
        this.setState({ uploadingPdf: false, pdfUrl: response.data }, () => {
          const options = {
            customUI: ({ onClose }) => {
              return (
                <div className="react-confirm-alert-body">
                  <h1>{messages.upload_pdf_title}</h1>
                  <p>{messages.upload_pdf_status_done}</p>
                  <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
                    <Button
                      style={{ margin: '0 auto', marginTop: '20px', width: '100px' }}
                      bsStyle="info"
                      onClick={onClose}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              );
            },
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
          };
          confirmAlert(options);
        });
      })
      .catch(() => {
        this.setState({ uploadingPdf: false }, () => {
          const options = {
            customUI: ({ onClose }) => {
              return (
                <div className="react-confirm-alert-body">
                  <h1>{messages.upload_pdf_title}</h1>
                  <p>{messages.upload_pdf_status_error}</p>
                  <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
                    <Button
                      style={{ margin: '0 auto', marginTop: '20px', width: '100px' }}
                      bsStyle="info"
                      onClick={onClose}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              );
            },
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
          };
          confirmAlert(options);
        });
      });
  }; // End uploadFiles

  uploadPDF = formData => {
    const { editionId } = this.props;
    return Axios.post('/api/editions/' + editionId + '/uploads/pdfs.json', formData);
  };

  render() {
    const {
      user,
      currentPage,
      pageAmount,
      pageNames,
      editionTitle,
      campaign,
      imageExport,
      website,
      init,
      isEditionInCart,
      showWebPreview,
      itemName,
      editionSettings,
      printingR,
      previewingR,
      exportingR,
      savingR,
      unsavedR,
      campaignOptionsR,
      ccToolbar,
      pendingTimelineR,
      timelineR,
      showSettings,
      showSettingsF,
      closeSettingsF,
      edition,
    } = this.props;
    const {
      showSaveTo,
      savingTo,
      showTimelineOverlay,
      openImageOverlay,
      openedImage,
      pending,
      saveBeforeToCart,
    } = this.state;
    const {
      intl: { messages },
    } = this.props;
    let leftDisable = false;
    let rightDisable = false;
    let saveDisable = false;
    let previewDisable = false;
    let printDisable = false;
    let exportDisable = false;
    let goToCampaignDisable = false;
    let saveClass = 'default';
    const isSubmission = edition?.is_submission;
    let orderAllowed = true;
    let editionOrdered = false;

    if (currentPage === 0) {
      leftDisable = true;
    }

    if (currentPage + 1 === pageAmount) {
      rightDisable = true;
    }


    if (typeof edition?.placed_orders && edition?.placed_orders && edition?.placed_orders?.length > 0) {
      editionOrdered = true;
      orderAllowed = false;
    }

    if (editionOrdered && !may('ROLE_ROOT_EMPLOYEE', user.roles) && !website) {
      saveDisable = true;
    }

    if (savingR) {
      saveDisable = true;
      previewDisable = true;
      goToCampaignDisable = true;
    }

    if (isSubmission) {
      goToCampaignDisable = true;
      this.showSaveTo(false);
    }

    if (previewingR || printingR || exportingR) {
      previewDisable = true;
      printDisable = true;
      exportDisable = true;
    }

    if (unsavedR) {
      saveClass = 'danger';
    }


    if (
      (this.props.placeholder && this.props.editionRV?.placeholder === null) ||
      (!may('ROLE_ADMIN', user.roles) && edition?.creator?.username !== user?.username)
    ) {
      orderAllowed = false;
    }

    const pdfDownloadOption = user?.effective_pdf_download_option?.value || OPTION_DISABLED;
    const canDownloadPrintPdf = !!(may('ROLE_ROOT_EMPLOYEE', user.roles) || pdfDownloadOption === OPTION_ENABLED);

    const trackingPixelExampleCode =
      '<!-- Meta Pixel Verification Code -->' +
      '<meta name="facebook-domain-verification" content="rxl23j….." /> ' +
      '<!-- Meta Pixel Code --> ' +
      '<script>' +
      '!function(f,b,e,v,n,t,s)' +
      '... ' +
      '</script> ' +
      '<noscript> ' +
      '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=012345678&ev=PageView&noscript=1"/> ' +
      '</noscript> ' +
      '<!-- End Meta Pixel Code -->';

    return (
      <div className="cc-editor-toolbar" style={{ display: ccToolbar }}>
        {openImageOverlay && (
          <TimelineDialogModal
            closeImageOverlay={this.closeImageOverlay}
            openedImage={openedImage}
            openImageOverlay={openImageOverlay}
          />
        )}
        {showSaveTo && campaign && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage">
                <p>{messages.editor.save_to}</p>
                <div className="row">
                  {campaignOptionsR && !savingTo ? (
                    <FormGroup>
                      <FormAutocomplete
                        name="campaign"
                        label={messages.editor.choose_a_campaign}
                        value={campaign.id}
                        ref={ref => {
                          this.campaignSelect = ref;
                        }}
                        options={campaignOptionsR}
                      />
                      <FormInput
                        type="text"
                        label={messages.editor.name_for_edition}
                        name="title"
                        value={editionTitle || ''}
                        ref={ref => {
                          this.title = ref;
                        }}
                      />
                      <Button bsStyle="primary" bsSize="large" onClick={this.saveTo}>
                        {messages.editor.save}
                      </Button>
                    </FormGroup>
                  ) : (
                    <Loader />
                  )}
                </div>

                <Button bsStyle="danger" bsSize="large" onClick={this.closeSaveTo} className="cancelIE">
                  {messages.editor.abort}
                </Button>
              </div>
            </div>
          </ContextModal>
        )}
        {showTimelineOverlay && (
          <TimelineOverlay
            timeline={timelineR}
            openImageOverlay={this.openImageOverlay}
            undo={this.undo}
            closeTimelineOverlay={this.closeTimelineOverlay}
            pending={pending}
            pendingTimelineR={pendingTimelineR}
            getTimelineR={this.props.getTimelineR}
            editionId={this.props.editionId}
            timelineStartR={this.props.timelineStartR}
            showLoadMoreEntriesR={this.props.showLoadMoreEntriesR}
          />
        )}
        {showSettings && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="editor_settings">
                <h3>{messages.editor.settings}</h3>
                <div className="row">
                  <FormGroup>
                    <FormInput
                      type="text"
                      label={messages.editor.name_for_edition}
                      name="title"
                      value={editionTitle || ''}
                      ref={ref => {
                        this.title = ref;
                      }}
                    />
                    {website && (
                      <FormInput
                        type="text"
                        label={messages.website_title}
                        name="websiteTitle"
                        value={
                          _.find(editionSettings, ['name', 'websiteTitle'])
                            ? _.find(editionSettings, ['name', 'websiteTitle']).value || ''
                            : editionTitle
                        }
                        onBlur={this.changeSettings}
                      />
                    )}
                    <FormInput
                      type="text"
                      label={messages.editor.short_summary}
                      name="exzerpt"
                      value={
                        _.find(editionSettings, ['name', 'exzerpt'])
                          ? _.find(editionSettings, ['name', 'exzerpt']).value || ''
                          : ''
                      }
                      onBlur={this.changeSettings}
                    />
                  </FormGroup>
                  {/*
                  <h4>{messages.editor.serial_letter}</h4>
                  <FormGroup>
                    <FormSwitchery
                      name="serialletter"
                      checked={
                        (_.find(editionSettings, ['name', 'serialletter'])
                          ? _.find(editionSettings, ['name', 'serialletter']).value || ''
                          : false)
                      }
                      value
                      label={messages.editor.activate_serial_letter}
                      onChange={this.changeSettings}
                    />
                  </FormGroup>
                   {this.state.showSerialLetterOptions || this.state.showSerialLetterOptions === "1"  ?
                  */}
                  {this.props.placeholder ? (
                    <>
                      <FormGroup>
                        <div className="databaseUpload">
                          <h4>{messages.editor.serial_letter_database}</h4>
                          <p>{messages.editor.serial_letter_database_upload_info}</p>
                          <p>{messages.editor.serial_letter_info}</p>
                          {/* eslint-disable-next-line max-len */}
                          {/* <table style={{width:"50%", margin:"0 auto", marginBottom:"30px"}} className="table table-sm placeholderTable"> */}
                          {/*  <tbody> */}
                          {/*  <tr> */}
                          {/*    <td>{messages.editor.first_name}</td> */}
                          {/*    <td>{messages.editor.name}</td> */}
                          {/*    <td>{messages.editor.city}</td> */}
                          {/*  </tr> */}
                          {/*  <tr> */}
                          {/*    <td>Max</td> */}
                          {/*    <td>Mustermann</td> */}
                          {/*    <td>Musterstadt</td> */}
                          {/*  </tr> */}
                          {/*  </tbody> */}
                          {/* </table> */}
                          <input
                            type="file"
                            onChange={e => {
                              this.onFileChange(e);
                            }}
                            accept=".xlsx,.xls,.csv"
                          />
                          {this.props.placeholderPendingR ? (
                            <Loader />
                          ) : (
                            <Button bsStyle="success" bsSize="large" onClick={this.onFileUpload}>
                              <div className="uploadPlaceholder">
                                <span>{messages.editor.serial_letter_database_upload}</span>
                              </div>
                            </Button>
                          )}
                          {this.props.placeholderErrorR ? (
                            <p className="text-danger m-t-20">
                              <i className="far fa-exclamation-triangle" />
                              <span> {messages.placeholder_upload_error}</span>
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </FormGroup>
                      <h5>{messages.editor.preview_of_database}</h5>
                      <table className="table table-sm placeholderTable">
                        <tbody>
                          {this.props.editionRV !== null && this.props.editionRV.placeholder !== null ? (
                            this.props.editionRV.placeholder.map((placeholder, i) => {
                              return (
                                <tr>
                                  <td>{i}</td>
                                  {placeholder.map((placeholder2, ix) => {
                                    return <td>{placeholder2}</td>;
                                  })}
                                </tr>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {website && (
                  <div className="row m-t-20">
                    <h4>{messages.website_analyse}</h4>
                    <Col md={12} className="p-l-0 p-r-0">
                      <FormGroup>
                        <FormInput
                          type="text"
                          label={messages.editor.measurement_id}
                          name="messId"
                          value={
                            _.find(editionSettings, ['name', 'messId'])
                              ? _.find(editionSettings, ['name', 'messId']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                          placeholder="G-12345ABC"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12} className="p-l-0 p-r-0">
                      <FormGroup>
                        <FormInput
                          type="text"
                          label={messages.google_tag_manager + ' ' + messages.google_tag_manager_id}
                          name="gtmId"
                          value={
                            _.find(editionSettings, ['name', 'gtmId'])
                              ? _.find(editionSettings, ['name', 'gtmId']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                          placeholder="GTM-12345"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12} className="p-l-0 p-r-0">
                      <FormGroup>
                        <FormInput
                          type="text"
                          label={
                            messages.google_search_console +
                            ' - ' +
                            messages.gsc_html_tag +
                            ' (' +
                            messages.content_id +
                            ')'
                          }
                          name="googlesearchconsole"
                          value={
                            _.find(editionSettings, ['name', 'googlesearchconsole'])
                              ? _.find(editionSettings, ['name', 'googlesearchconsole']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                          placeholder="Xu9c238409d8JDSF83fdsc830ZmhU..."
                          infoText={messages.gsc_html_tag_example}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12} className="p-l-0 p-r-0">
                      <FormGroup>
                        <FormInput
                          type="text"
                          label={messages.linkedin_insight_tag + ' ' + messages.linkedin_partner_id}
                          name="linkedIn"
                          value={
                            _.find(editionSettings, ['name', 'linkedIn'])
                              ? _.find(editionSettings, ['name', 'linkedIn']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                          placeholder="123456"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12} className="p-l-0 p-r-0">
                      <FormGroup className="tracking_pixel_code_editor_form_group">
                        <FormCodeEditor
                          type="text"
                          label={messages.tracking_pixel + ' ' + messages.tracking_pixel_data}
                          name="trackingPixel"
                          value={
                            _.find(editionSettings, ['name', 'trackingPixel'])
                              ? _.find(editionSettings, ['name', 'trackingPixel']).value || ''
                              : ''
                          }
                          onChange={this.changeSettings}
                          infoText={messages.tracking_pixel_help_text + trackingPixelExampleCode}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12} className="p-l-0 p-r-0 favIconPreview">
                      <h4>{messages.upload_website_favicon}</h4>
                      <FormGroup>
                        <FormImageWithPreview
                          label={messages.upload_website_favicon_label}
                          infoText={messages.upload_website_favicon_info}
                          name="lpFavIcon"
                          url={
                            _.find(editionSettings, ['name', 'lpFavIcon'])
                              ? _.find(editionSettings, ['name', 'lpFavIcon']).value || ''
                              : ''
                          }
                          onChange={this.fileUpload}
                          onReset={this.fileReset}
                          pending={false}
                          disabled={false}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <h4>{messages.upload_pdf_title}</h4>
                      <div className="uploadPDF">
                        <FormFile
                          label={messages.upload_pdf_title}
                          name="pdfUpload"
                          onChange={(name, value) => this.uploadPdfFiles(name, value)}
                          allowTypes="application/pdf"
                        />
                        <FormInput
                          type="text"
                          label={messages.link_to_uploaded_pdf}
                          name="pdfLink"
                          value={this.state.pdfUrl}
                        />
                        {this.state.uploadingPdf ? <Loader /> : <></>}
                      </div>
                    </Col>
                    <Col md={12} className="p-l-0 p-r-0">
                      <h4>{messages.contact_info}</h4>
                      <p>{messages.editor.information_for_contact}</p>
                      <FormGroup>
                        <FormInput
                          type="text"
                          label={messages.editor.mail_receiver}
                          name="contactFormEmail"
                          value={
                            _.find(editionSettings, ['name', 'contactFormEmail'])
                              ? _.find(editionSettings, ['name', 'contactFormEmail']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                      </FormGroup>
                    </Col>
                    <h4>{messages.legal_information}</h4>
                    <Col md={6} className="p-l-0">
                      <p>{messages.editor.information_for_imprint}</p>
                      <FormGroup>
                        <FormInput
                          type="text"
                          label={messages.editor.responsible_for_content + '*'}
                          name="impressumverantwortlicher"
                          value={
                            _.find(editionSettings, ['name', 'impressumverantwortlicher'])
                              ? _.find(editionSettings, ['name', 'impressumverantwortlicher']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="text"
                          label={messages.editor.company_association + '*'}
                          name="impressumfirma"
                          value={
                            _.find(editionSettings, ['name', 'impressumfirma'])
                              ? _.find(editionSettings, ['name', 'impressumfirma']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="text"
                          label={messages.editor.street + '*'}
                          name="impressumstrasse"
                          value={
                            _.find(editionSettings, ['name', 'impressumstrasse'])
                              ? _.find(editionSettings, ['name', 'impressumstrasse']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="text"
                          label={messages.editor.zip_code + '*'}
                          name="impressumplz"
                          value={
                            _.find(editionSettings, ['name', 'impressumplz'])
                              ? _.find(editionSettings, ['name', 'impressumplz']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="text"
                          label={messages.editor.city + '*'}
                          name="impressumort"
                          value={
                            _.find(editionSettings, ['name', 'impressumort'])
                              ? _.find(editionSettings, ['name', 'impressumort']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="email"
                          label={messages.editor.email + '*'}
                          name="impressummail"
                          value={
                            _.find(editionSettings, ['name', 'impressummail'])
                              ? _.find(editionSettings, ['name', 'impressummail']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="text"
                          label={messages.editor.phone_number + '*'}
                          name="impressumtelefon"
                          value={
                            _.find(editionSettings, ['name', 'impressumtelefon'])
                              ? _.find(editionSettings, ['name', 'impressumtelefon']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} className="p-r-0">
                      <p>{messages.editor.data_protection_information}</p>
                      <FormGroup>
                        <FormInput
                          type="text"
                          label={messages.editor.responsible + '*'}
                          name="datenschutzverantwortlicher"
                          value={
                            _.find(editionSettings, ['name', 'datenschutzverantwortlicher'])
                              ? _.find(editionSettings, ['name', 'datenschutzverantwortlicher']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="text"
                          label={messages.editor.company_association + '*'}
                          name="datenschutzfirma"
                          value={
                            _.find(editionSettings, ['name', 'datenschutzfirma'])
                              ? _.find(editionSettings, ['name', 'datenschutzfirma']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="text"
                          label={messages.editor.street + '*'}
                          name="datenschutzstrasse"
                          value={
                            _.find(editionSettings, ['name', 'datenschutzstrasse'])
                              ? _.find(editionSettings, ['name', 'datenschutzstrasse']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="text"
                          label={messages.editor.zip_code + '*'}
                          name="datenschutzplz"
                          value={
                            _.find(editionSettings, ['name', 'datenschutzplz'])
                              ? _.find(editionSettings, ['name', 'datenschutzplz']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="text"
                          label={messages.editor.city + '*'}
                          name="datenschutzort"
                          value={
                            _.find(editionSettings, ['name', 'datenschutzort'])
                              ? _.find(editionSettings, ['name', 'datenschutzort']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="email"
                          label={messages.editor.email + '*'}
                          name="datenschutzmail"
                          value={
                            _.find(editionSettings, ['name', 'datenschutzmail'])
                              ? _.find(editionSettings, ['name', 'datenschutzmail']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="text"
                          label={messages.editor.phone_number + '*'}
                          name="datenschutztelefon"
                          value={
                            _.find(editionSettings, ['name', 'datenschutztelefon'])
                              ? _.find(editionSettings, ['name', 'datenschutztelefon']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="text"
                          label={messages.editor.data_protection_officer}
                          name="datenschutzbeauftragter"
                          value={
                            _.find(editionSettings, ['name', 'datenschutzbeauftragter'])
                              ? _.find(editionSettings, ['name', 'datenschutzbeauftragter']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                        <FormInput
                          type="text"
                          label={messages.editor.data_protection_authority + '*'}
                          name="datenschutzbehoerde"
                          value={
                            _.find(editionSettings, ['name', 'datenschutzbehoerde'])
                              ? _.find(editionSettings, ['name', 'datenschutzbehoerde']).value || ''
                              : ''
                          }
                          onBlur={this.changeSettings}
                        />
                      </FormGroup>
                    </Col>
                  </div>
                )}
                <Button bsStyle="primary" bsSize="large" onClick={this.saveSettings}>
                  {messages.editor.change}
                </Button>
                <Button bsStyle="warning" bsSize="large" onClick={() => closeSettingsF()} className="m-l-20">
                  {messages.editor.abort}
                </Button>
              </div>
            </div>
          </ContextModal>
        )}
        <div className="left-tools">
          <ButtonGroup bsSize="small">
            <Button onClick={this.pageDown} disabled={leftDisable}>
              <i className="fa fa-angle-left" />
            </Button>
            <DropdownButton title={messages.go_to} id="page-select" bsSize="small" onSelect={this.gotoPage}>
              {pageNames}
            </DropdownButton>
            <Button onClick={this.pageUp} disabled={rightDisable}>
              <i className="fa fa-angle-right" />
            </Button>
          </ButtonGroup>
        </div>
        <div className="center-tools">
          <p className="document-title">
            <span id="campaign-name">{campaign?.title}</span>
            {campaign?.title?.length > 0 ? ': ' : ' '}
            <span id="document-name">
              {editionTitle} ({itemName})
            </span>
          </p>
        </div>
        <div className="right-tools">
          <ButtonGroup bsSize="small">
            <Button
              onClick={() => {
                this.goBackToCampaign(campaign?.id);
              }}
              disabled={goToCampaignDisable}
            >
              <i className={previewingR ? 'fad fa-spinner-third fa-spin' : 'fal fa-arrow-circle-left'} />
              <span> {messages.go_back_to_campaign}</span>
            </Button>
            {imageExport && !this.props.hidePreview ? (
              <Button
                onClick={() => {
                  this.getPDF('image');
                }}
                disabled={exportDisable}
              >
                <i className={exportingR ? 'fad fa-spinner-third fa-spin' : 'far fa-image'} />
                <span> {messages.editor.export_image}</span>
              </Button>
            ) : website ? (
              <Button onClick={showWebPreview} disabled={previewDisable}>
                <i className="far fa-browser" /> <span> {messages.editor.preview}</span>
              </Button>
            ) : (
              <>
                {!this.props.hidePreview && (
                  <Button
                    onClick={() => {
                      this.getPDF('preview');
                    }}
                    disabled={previewDisable}
                  >
                    <i className={previewingR ? 'fad fa-spinner-third fa-spin' : 'far fa-file-pdf'} />
                    <span> {messages.editor.preview}</span>
                  </Button>
                )}
                {canDownloadPrintPdf && (
                  <Button
                    onClick={() => {
                      this.getPDF('print');
                    }}
                    disabled={printDisable}
                  >
                    <i className={printingR ? 'fad fa-spinner-third fa-spin' : 'far fa-file-pdf'} />{' '}
                    <span> {messages.editor.print_pdf}</span>
                  </Button>
                )}
              </>
            )}
            {!init && may('ROLE_ROOT_EMPLOYEE', user.roles) ? (
              <Button bsStyle={saveClass} onClick={this.initAction} disabled={saveDisable}>
                <i className="far fa-save" /> <span> {messages.editor.initialize}</span>
              </Button>
            ) : (
              <>
                <Button bsStyle={saveClass} onClick={this.save} disabled={saveDisable}>
                  <i className="far fa-save" /> <span> {messages.editor.save}</span>
                </Button>
                <Button onClick={this.showSaveTo} disabled={saveDisable}>
                  <i className="far fa-copy" /> <span> {messages.editor.save_to}</span>
                </Button>
                <Button onClick={() => showSettingsF()}>
                  <i className="far fa-cog" /> <span> {messages.editor.settings}</span>
                </Button>
                {(isEditionInCart || saveBeforeToCart || !orderAllowed) && !isSubmission ? (
                  <Button disabled>
                    <i className="fas fa-cart-plus" /> <span> {messages.editor.order}</span>
                  </Button>
                ) : (
                  !imageExport &&
                  orderAllowed && (
                    <Button onClick={this.addToCart} disabled={isSubmission}>
                      <i className="fas fa-cart-plus" />
                      <span> {messages.editor.order}</span>
                    </Button>
                  )
                )}
                <Button onClick={this.showTimelineOverlay}>
                  <i className="fal fa-clock" /> <span> {messages.editor.versions}</span>
                </Button>
              </>
            )}
          </ButtonGroup>
          {/* keine Functions hinterlegt
          <DropdownButton title="..." id="mobile-file">
            <MenuItem eventKey="1">
              <i className="far fa-file-pdf"></i><span> {messages.editor.preview}</span>
            </MenuItem>
            <MenuItem bsStyle={saveClass} eventKey="2" disabled={saveDisable}>
              <i className="far fa-save"></i> <span> {messages.editor.save}</span>
            </MenuItem>
            <MenuItem eventKey="3" disabled={saveDisable}>
              <i className="far fa-copy"></i> <span> {messages.editor.save_to}</span>
            </MenuItem>
            <MenuItem eventKey="5">
              <i className="far fa-cog"></i> <span> {messages.editor.settings}</span>
            </MenuItem>
            {isEditionInCart || saveBeforeToCart ? (
              <MenuItem eventKey="4" disabled>
                <i className="fas fa-cart-plus"></i> <span>{messages.editor.order}</span>
              </MenuItem>
            ) : (
              !imageExport && (
                <MenuItem eventKey="4" onClick={this.addToCart}>
                  <i className="fas fa-cart-plus"></i> <span> {messages.editor.order}</span>
                </MenuItem>
              )
            )}
          </DropdownButton>
          */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addToCart: edition => {
      dispatch(cartAddEdition(edition));
    },
    getPDFR: (data, data2, messages) => {
      dispatch(getPDFR(data, data2, messages));
    },
    setVariables: data => {
      dispatch(setVariables(data));
    },
    uploadExcelFileR: (data, editionId) => {
      dispatch(uploadExcelFileR(data, editionId));
    },
    getTimelineR: (data, start, limit) => {
      dispatch(getTimelineR(data, start, limit));
    },
    uploadFavIcon: args => {
      formStatusDispatchHelper('lpFavIcon', '/api/editions/uploads/favicons.json', args, 'post', dispatch);
    },
    doNotify: (code, text) => {
      dispatch(addNotification(code, text));
    },
  };
};
const mapStateToProps = (state, { edition }) => {
  return {
    isEditionInCart: !!state.cart.items.find(item => item.edition.id === edition.id),
    appIntl: state.intl,
    ccToolbar: state.layout.ccToolbar,
    cartPending: state.cart.addToCartPending,
    placeholderPendingR: state.editor.placeholderPendingR,
    editionRV: state.editor.editionRV,
    timelineR: state.editor.timelineR,
    pendingTimelineR: state.editor.pendingTimelineR,
    timelineStartR: state.editor.timelineStartR,
    updateTimelineR: state.editor.updateTimelineR,
    showLoadMoreEntriesR: state.editor.showLoadMoreEntriesR,
    placeholderErrorR: state.editor.placeholderErrorR,
    formStatusLandingPageFavIcon: state.formStatus.lpFavIcon,
  };
};
export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditorToolbar)
  )
);
