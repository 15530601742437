import { DELETER_PENDING, DELETER_FULFILLED, DELETER_REJECTED } from 'actions/DeleterActions';

const standardState = {
  pending: false,
  hasError: false,
  errors: [],
  data: {},
};

const initialState = {
  client: { ...standardState },
  item: { ...standardState },
  car: { ...standardState },
  attribute: { ...standardState },
  attributeGroup: { ...standardState },
  attributeCategories: { ...standardState },
  brand: { ...standardState },
  page: { ...standardState },
  cmsElement: { ...standardState },
  category: { ...standardState },
  docType: { ...standardState },
  pipeline: { ...standardState },
  questionnaire: { ...standardState },
  team: { ...standardState },
  template: { ...standardState },
  productTemplates: { ...standardState },
  user: { ...standardState },
  variant: { ...standardState },
  campaign: { ...standardState },
  edition: { ...standardState },
  doc: { ...standardState },
  leadDoc: { ...standardState },
  orderDoc: { ...standardState },
  model: { ...standardState },
  dataPush: { ...standardState },
  whiteLabel: { ...standardState },
  whiteLabelPlugin: { ...standardState },
  leadCatcher: { ...standardState },
  filterset: { ...standardState },
  dynamicDataDefinition: { ...standardState },
  externalAccount: { ...standardState },
  modelGroup: { ...standardState },
  modelAsset: { ...standardState },
  leadPerMailRule: { ...standardState },
  carService: { ...standardState },
  carServiceStation: { ...standardState },
  removeUserFromTeam: { ...standardState },
  file: { ...standardState },
  cmsTemplate: { ...standardState },
  docTemplate: { ...standardState },
  autoresponse: { ...standardState },
  quote: { ...standardState },
  invoice: { ...standardState },
  mainMenuVersion: { ...standardState },
  leadTagger: { ...standardState },
  share: { ...standardState },
  coupon: { ...standardState },
  leadAssignmentStrategy: { ...standardState },
  leadEscalationRule: { ...standardState },
  allowedDomain: { ...standardState },
};

export default function deleter(state = initialState, action) {
  const { deleterName } = (action && action.payload) || {};

  switch (action.type) {
    case DELETER_PENDING: {
      const newState = { ...state };
      const oldDataState = newState[deleterName];

      newState[deleterName] = {
        ...oldDataState,
        pending: true,
        hasError: false,
        errors: [],
        data: { ...initialState[deleterName].data },
      };

      return newState;
    }

    case DELETER_FULFILLED: {
      const newState = { ...state };
      const oldDataState = newState[deleterName];

      newState[deleterName] = {
        ...oldDataState,
        pending: false,
        hasError: false,
        errors: [],
        data: action.payload.response.data,
      };

      return newState;
    }

    case DELETER_REJECTED: {
      const newState = { ...state };
      const oldDataState = newState[deleterName];

      newState[deleterName] = {
        ...oldDataState,
        pending: false,
        hasError: true,
        errors: action.payload.response ? action.payload.response.data : {},
        data: { ...initialState[deleterName].data },
      };

      return newState;
    }
  }

  return state;
}
