import _ from 'lodash';
import { UPLOAD_FILE_ERROR, UPLOAD_FILE_FINISHED, UPLOAD_FILE_START } from 'actions/UploadActions';

const initialState = {
  mediaFolders: [],
  mediaFiles: [],
  saving: false,
  pending: false,
  filesToUpload: 0,
  updater: false,
  already_exist: false,
  storeFR: ['main'],
  pixabaySearching: [],
  pixabayFilesCache: [],
  pixabaySearchingTextCache: [],
  pendingP: false,
  already_exist_errors: [],
};

const FILES = 'mediaFiles';
const FOLDERS = 'mediaFolders';

/**
 * Reusable logic for removing file or folder from the state
 * @param {Object} state current state
 * @param {string} payloadId id of the file or folder to trash
 * @param {string} type "mediaFiles" or "mediaFolders"
 * @returns updated state
 */
const removeItem = (state, payloadId, type) => {
  const updatedCollection = state[type].filter(({ id }) => id !== payloadId);
  return { ...state, [type]: updatedCollection };
};

/**
 * Reusable logic for marking file and folder "isInTrash" true or false
 * @param {Object} state current state
 * @param {string} payloadId id of the file or folder to trash
 * @param {string} type "mediaFiles" or "mediaFolders"
 * @param {bool} isInTrash true to delete, false to undelete
 * @returns updated state
 */
const itemIsInTrash = (state, payloadId, type, isInTrash) => {
  const updatedCollection = state[type].map(fileOrFolder => {
    if (fileOrFolder.id === payloadId) {
      return { ...fileOrFolder, is_in_trash: isInTrash };
    }
    return { ...fileOrFolder };
  });

  return { ...state, [type]: updatedCollection };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_MEDIA_LIBRARY_PENDING': {
      return { ...state, pending: true, updater: true, storeFr: ['main'] };
    }
    case 'GET_MEDIA_LIBRARY_FULFILLED': {
      const { mediaFolders, mediaFiles } = payload;
      return { ...state, mediaFolders, mediaFiles, pending: false, updater: false };
    }
    case 'GET_MEDIA_LIBRARY_FOLDER_PENDING': {
      return { ...state, pending: true, updater: true };
    }
    case 'GET_MEDIA_LIBRARY_FOLDER_FULFILLED': {
      const { mediaFolders, mediaFiles } = state;
      let foldersConcat = mediaFolders;
      let mediaConcat = mediaFiles;
      if (payload.mediaFolders && payload.mediaFolders.length !== 0) {
        foldersConcat = mediaFolders.concat(payload.mediaFolders);
      }
      if (payload.mediaFiles && payload.mediaFiles.length !== 0) {
        mediaConcat = mediaFiles.concat(payload.mediaFiles);
      }
      return { ...state, mediaFolders: foldersConcat, mediaFiles: mediaConcat, pending: false, updater: false };
    }
    case 'GET_MEDIA_LIBRARY_FOLDER_SORTED_PENDING': {
      return { ...state, pending: true, updater: true };
    }
    case 'GET_MEDIA_LIBRARY_FOLDER_SORTED_FULFILLED': {
      const { mediaFolders, mediaFiles } = state;
      let foldersConcat = mediaFolders;
      let mediaConcat = mediaFiles;
      if (payload.mediaFolders && payload.mediaFolders.length !== 0) {
        const filteredFolders = _.filter(mediaFolders, x => x.parent !== payload.mediaFolders[0].parent);
        foldersConcat = filteredFolders.concat(payload.mediaFolders);
      }
      if (payload.mediaFiles && payload.mediaFiles.length !== 0) {
        const filteredFiles = _.filter(mediaFiles, x => x.parent !== payload.mediaFiles[0].parent);
        mediaConcat = filteredFiles.concat(payload.mediaFiles);
      }

      return { ...state, mediaFolders: foldersConcat, mediaFiles: mediaConcat, pending: false, updater: false };
    }
    case 'CREATE_FOLDER_FULFILLED': {
      const mediaFolders = [...state.mediaFolders, payload];
      return { ...state, mediaFolders };
    }
    case 'EDIT_FOLDER_PENDING': {
      return { ...state, saving: true };
    }
    case 'EDIT_FOLDER_FULFILLED': {
      const { mediaFolders } = state;
      const index = _.findIndex(mediaFolders, folder => folder.id === payload.id);
      mediaFolders[index].name = payload.name;
      return { ...state, mediaFolders, saving: false };
    }
    case 'EDIT_FILE_PENDING': {
      return { ...state, saving: true };
    }
    case 'EDIT_FILE_FULFILLED': {
      const { mediaFiles } = state;
      const index = _.findIndex(mediaFiles, file => file.id === payload.id);
      mediaFiles[index] = payload;

      return { ...state, mediaFiles, saving: false };
    }

    case 'DELETE_FOLDER_FULFILLED': {
      return itemIsInTrash(state, payload, FOLDERS, true);
    }

    case 'ERASE_FOLDER_FULFILLED': {
      return removeItem(state, payload, FOLDERS);
    }

    case 'UNDELETE_FOLDER_FULFILLED': {
      return itemIsInTrash(state, payload, FOLDERS, false);
    }

    case 'DELETE_FILE_FULFILLED': {
      return itemIsInTrash(state, payload, FILES, true);
    }

    case 'ERASE_FILE_FULFILLED': {
      return removeItem(state, payload, FILES);
    }

    case 'UNDELETE_FILE_FULFILLED': {
      return itemIsInTrash(state, payload, FILES, false);
    }

    case 'F_UPLOAD_PENDING': {
      return { ...state, saving: true };
    }

    case 'F_UPLOAD_FULFILLED': {
      const firstFile = payload[0];
      const mediaFiles = [...state.mediaFiles];

      let alreadyExist = false;
      let alreadyExistErrorsAdd = false;

      if (firstFile === 'already_exist') {
        alreadyExist = true;
        alreadyExistErrorsAdd = true;
      } else {
        mediaFiles.push(firstFile);
      }

      // when a same file is uploaded as an existing in trash, we "undelete" the trashed
      const mediaFilesUniqueById = mediaFiles.reduce((acc, current) => {
        const existing = acc.find(({ id }) => id === current.id);
        if (existing) {
          if (existing.is_in_trash && !current.is_in_trash) {
            // replace trashed one with the not trashed
            const index = acc.findIndex(({ id }) => id === current.id);
            acc.splice(index, 1, current);
          }

          return acc;
        }

        return [...acc, current];
      }, []);

      return {
        ...state,
        saving: false,
        mediaFiles: mediaFilesUniqueById,
        already_exist: alreadyExist,
        already_exist_errors: [...state.already_exist_errors, alreadyExistErrorsAdd],
        filesToUpload: payload.files,
      };
    }

    case 'SET_VARIABLES_MEDIA': {
      let alreadyExist; // TODO: shouldn't the default be true? Now its either undefined or set to false..
      let errors = [...state.already_exist_errors];

      if (payload === 'already_exist_false') {
        alreadyExist = false;
      } else if (payload === 'already_exist_errors_reset') {
        errors = [];
      }

      return { ...state, already_exist: alreadyExist, already_exist_errors: errors };
    }

    case 'UPDATE_STORE': {
      return { ...state, storeFR: payload };
    }
    case 'CONNECT_TO_PIXABAY_PENDING': {
      return { ...state, pendingP: true };
    }

    case 'CONNECT_TO_PIXABAY_FULFILLED': {
      const [searchText, searchFile] = payload;

      const pixabaySearchingTextCache = [...state.pixabaySearchingTextCache, searchText];
      const pixabayFilesCache = [...state.pixabayFilesCache, searchFile];

      return {
        ...state,
        pixabaySearching: searchFile,
        pixabayFilesCache,
        pixabaySearchingTextCache,
        pendingP: false,
      };
    }

    case UPLOAD_FILE_START: {
      return { ...state, saving: true, pending: true };
    }

    case UPLOAD_FILE_FINISHED: {
      if (payload?.response?.config?.url?.includes('medialibraries')) {
        const _payload = payload?.response?.data;
        const firstFile = _payload[0];
        const mediaFiles = [...state.mediaFiles];

        let alreadyExist = false;
        let alreadyExistErrorsAdd = false;

        if (firstFile === 'already_exist') {
          alreadyExist = true;
          alreadyExistErrorsAdd = true;
        } else {
          mediaFiles.push(firstFile);
        }

        // when a same file is uploaded as an existing in trash, we "undelete" the trashed
        const mediaFilesUniqueById = mediaFiles.reduce((acc, current) => {
          const existing = acc.find(({ id }) => id === current.id);
          if (existing) {
            if (existing.is_in_trash && !current.is_in_trash) {
              // replace trashed one with the not trashed
              const index = acc.findIndex(({ id }) => id === current.id);
              acc.splice(index, 1, current);
            }

            return acc;
          }

          return [...acc, current];
        }, []);

        return {
          ...state,
          saving: false,
          mediaFiles: mediaFilesUniqueById,
          already_exist: alreadyExist,
          already_exist_errors: [...state.already_exist_errors, alreadyExistErrorsAdd],
          filesToUpload: _payload.files,
          pending: false,
        };
      }
      return {
        ...state,
        saving: false,
        pending: false,
      };
    }

    case UPLOAD_FILE_ERROR: {
      return { ...state, saving: false, pending: false };
    }
  }

  return state;
};
