import React from 'react';
import PropTypes from 'prop-types';

Address.propTypes = {
  data: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.string),
    street: PropTypes.string,
    houseNumber: PropTypes.string,
    apartmentNumber: PropTypes.string,
    addressLine2: PropTypes.string,
    zipcode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    countryName: PropTypes.string,
  }),
};

Address.defaultProps = {
  data: PropTypes.shape({
    types: ['mail', 'delivery', 'invoice'],
    street: '',
    houseNumber: '',
    apartmentNumber: '',
    addressLine2: '',
    zipcode: '',
    city: '',
    country: '',
    countryName: '',
  }),
};

function Address({ data, className, ...props }) {
  const {
    // types,
    street,
    houseNumber: houseNumberTemp1,
    house_number: houseNumberTemp2,
    apartmentNumber: apartmentNumberTemp1,
    apartment_number: apartmentNumberTemp2,
    addressLine2: addressLine2Temp1,
    address_line2: addressLine2Temp2,
    zipcode,
    city,
    country,
    countryName,
  } = data;
  const houseNumber = houseNumberTemp1 || houseNumberTemp2;
  const apartmentNumber = apartmentNumberTemp1 || apartmentNumberTemp2;
  const addressLine2 = addressLine2Temp1 ?? addressLine2Temp2;

  return (
    <div className={`address${className ? ' ' + className : ''}`} {...props}>
      <div className="street">
        {street} {houseNumber}
        {`${apartmentNumber ? ' (' + apartmentNumber + ')' : ''}`}
        {addressLine2 ? (
          <>
            <br />
            addressLine2
          </>
        ) : null}
      </div>
      <div className="city">
        {zipcode} {city}
      </div>
      {countryName && (
        <div className="country">
          {countryName} ({country})
        </div>
      )}
    </div>
  );
}

export default Address;
