import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import { injectIntl } from 'react-intl';

import Loader from 'components/layout/Loader';
import SimpleSubmitButton from 'components/layout/SimpleSubmitButton';
import Data from 'components/Data';

import FormInput from 'components/forms/FormInput';
import FormErrors from 'components/forms/FormErrors';

import EntityStateUpdater from 'component/EntityStateUpdaterV2';
import { communicationVideoModule } from '@chedri/base';
import globals from '../../../globals';

const { whiteLabelSettings } = globals;

class LoginForm extends EntityStateUpdater {
  constructor(props) {
    super(props, { metaIdentifier: 'cmsLoginForm', dataProps: 'empty' }); // pass the configuration to entity state updater
  }

  formSubmit = e => {
    e.preventDefault();
    e.stopPropagation(); // if we are editing a page, we have to make sure the parent form will not be submitted

    this.validateEntity(() => {
      this.props.onSubmit(this.state.entity.__getStoredData());
    });
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      user,
      buttonTitle,
      linkClicked,
      isInCall,
    } = this.props;
    const { entity, entityLoaded } = this.state;

    return (
      <Row>
        <Data dataName="cmsLoginForm" /> {/* fetch meta data */}
        <Col xs={12}>
          {entityLoaded ? (
            <Form onSubmit={this.formSubmit}>
              <FormErrors
                formStatus={{
                  hasError: user.loginError,
                  errors: user.loginErrors,
                }}
              />

              <div class="form-group-attached p-b-15">
                <FormInput
                  label={messages.username}
                  name="username"
                  value={entity.getUsername}
                  meta={entity.getUsernameMetaInformation}
                  validation={entity.getUsernameValidationResult}
                  onChange={this.updateEntity}
                  disabled={user.loginPending}
                  autoComplete="username"
                />
                <FormInput
                  type="password"
                  label={messages.password}
                  name="password"
                  value={entity.getPassword}
                  meta={entity.getPasswordMetaInformation}
                  validation={entity.getPasswordValidationResult}
                  onChange={this.updateEntity}
                  disabled={user.loginPending}
                  autoComplete="current-password"
                />
              </div>

              <SimpleSubmitButton
                label={buttonTitle}
                iconClass="fad fa-key"
                entityChanged={this.getEntityChanged()}
                errorButton={this.hasValidationErrorButton()}
                pending={user.loginPending}
                block
                defaultStyle="primary"
                disabled={isInCall}
              />

              <div class="text-right m-t-20 hint-text fs-11">
                {whiteLabelSettings?.is_client_registration_allowed && whiteLabelSettings.name !== 'CreaCheck' && (
                  <>
                    <Link to={appIntl.urlPrefix + 'signup'} onClick={() => linkClicked('signup')}>
                      {messages.signup}
                    </Link>
                    {' | '}
                  </>
                )}
                <Link to={appIntl.urlPrefix + 'forgotpassword'} onClick={() => linkClicked('forgotpassword')}>
                  {messages.meta.forgot_password.linktext}
                </Link>
              </div>
            </Form>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    );
  }
}
LoginForm.defaultProps = {
  linkClicked: () => {},
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    meta: state.entityMeta,
    user: state.login.user,
    isInCall: communicationVideoModule.selectors.isInCall(state),
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(LoginForm)));
