import React from 'react';

export default class CheckoutSummaryPersonal extends React.Component {
  render() {
    const {
      intl: { messages },
      checkout,
      className,
    } = this.props;

    if (!checkout.userData) {
      return null;
    }

    return (
      <div className={className}>
        <h4 className="m-t-0">{messages.your_data}</h4>

        <h5 className="semi-bold">
          {checkout.userData.firstName} {checkout.userData.lastName}
        </h5>
        <address>
          {!!checkout.userData.company && (
            <React.Fragment>
              <strong>{checkout.userData.company}</strong> <br />
            </React.Fragment>
          )}
          {checkout.userData.street} {checkout.userData.houseNumber} {checkout.userData.apartmentNumber}
          <br />
          {checkout.userData.zipCode}, {checkout.userData.city}
        </address>
      </div>
    );
  }
}
CheckoutSummaryPersonal.defaultProps = {
  className: '',
};
